html,
body {
  height: 100%;
}
body {
  /*padding: 0 calc(17px - (100vw - 100%)) 0 0;*/
  /*Убрать после добавления newTheme на все страницы*/
  /* background-color: #f4f6f8 !important; */
}
.MuiTypography-root {
  cursor: default;
}
@font-face {
  font-family: 'rouble';
  src: url('/fonts/rouble.otf') format('opentype');
  unicode-range: U+20BD;
}
.MuiTypography-root a {
  cursor: pointer;
}
.number-rs {
  padding-right: 0.2em;
}
.MuiCalendarPicker-viewTransitionContainer > div {
  display: flex;
  justify-content: center;
}
/* в компоненте SelectDateRange съехали даты. css снизу решает проблему */
.MuiCalendarPicker-viewTransitionContainer > div {
  flex-direction: column;
}
.treeview .MuiTreeItem-content {
  display: block;
}

.list-inline .MuiTypography-displayBlock {
  display: inline;
}

/* .MuiTooltip-popper {
     z-index:9;
} */

/* .react-dropdown-select.custom-select {

} */

.infinite-scroll-component {
  height: 100%;
  overflow: hidden;
  padding: 3px 0;
}
.infinite-scroll-component__outerdiv {
  height: 100%;
}

.react-dropdown-select.custom-select .react-dropdown-select-dropdown {
  width: 100%;
}

.react-dropdown-select.custom-select .react-dropdown-select-dropdown {
  z-index: 4;
}
.withCustomScroll {
  scrollbar-color: #a3a3a3 transparent;
  scrollbar-width: thin;
  overflow: auto;
}
@-moz-document url-prefix() {
  .withCustomScroll {
    padding-bottom: 10px;
  }
}
.withCustomScroll::-webkit-scrollbar {
  width: 15px;
}
.withCustomScroll::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}
.withCustomScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}
.withCustomScroll::-webkit-scrollbar-track:hover {
  background-color: transparent;
}
.withCustomScroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #a3a3a3;
}
/* MSOMS-3638 */
.mode-dark .ps__rail-y {
  background-color: rgba(0, 0, 0, 0.3);
}
.mode-dark .ps__rail-y,
.ps__rail-x {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.scrollHidden {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollHidden::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
.inputNumberWithoutArrows > input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.inputNumberWithoutArrows > input[type='number']:hover,
.inputNumberWithoutArrows > input[type='number']:focus {
  -moz-appearance: number-input;
}
.inputNumberWithoutArrows > input[type='number']::-webkit-inner-spin-button,
.inputNumberWithoutArrows > input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.containerStyle {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  border-top: solid 1px #d5d5d6;
}

.cross-fade-leave {
  opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.cross-fade-height {
  transition: height 0.3s;
}
@media (max-width: 400px) {
  .MuiContainer-root {
    font-size: 16px;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
.height-preserving-container:empty {
  min-height: calc(var(--child-height));
  box-sizing: border-box;
}

.react-dadata__input:focus {
  border: none;
  outline: 2px solid #1a237e;
  box-shadow: none !important;
}
.sharingBtn {
    visibility: hidden;
}

@media all and (display-mode: standalone) {
    .sharingBtn {
        visibility: visible;
    }
}
